<template>
    <v-btn v-bind="props" color="success" class="button_success">
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
  name: 'ButtonSuccess',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
    .button_success{
        text-transform: inherit;
        height: 40px;
        padding: 0 19px;
        background-color: $tertiaryColor;
    }
</style>
