<template>
    <v-card
            class="mx-auto custom-card"
            v-bind="props"
    >
        <v-list-item>
            <v-list-item-content>
                <div class="overline mb-0 custom-card__title">
                    <slot name="card-title"></slot>
                </div>
            </v-list-item-content>
        </v-list-item>
        <div class="custom-card__body">
            <v-card-text>
                <slot name="card-body"></slot>
            </v-card-text>
        </div>
    </v-card>
</template>

<script>
export default {
  name: 'CustomCard',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
    .custom-card {
        padding-bottom: 10px;
        &__title{
            font-weight: 600;;
        }
        &__body{
            padding-bottom: 10px;
        }
        .v-card__text{
            padding: 0 16px;
        }
    }
</style>
