<template>
    <div class="full-wrapper">
        <CustomCard
                :props="{
                width: 500,
                outlined: true,
                loading: loading ? '#F88D4C' : false,
            }">
            <span slot="card-title">{{$t('message.forgotPassword')}}</span>
            <div slot="card-body">
                <v-form ref="forgotPasswordForm"
                        @submit.prevent="submit"
                        class="auth-form forgot-password-form"
                >
                    <div class="auth-form__body">
                        <div class="form-group textfield">
                            <v-text-field
                                    outlined
                                    required
                                    v-model="email"
                                    :rules="[
                                    _rules.required,
                                    _rules.email,
                                ]"
                                    :placeholder="$t('message.email')"
                            ></v-text-field>
                            <label class="label" for="event">
                                {{$t('message.email')}}
                                <i class="warning-icon">!</i>
                            </label>
                        </div>
                    </div>
                    <div class="auth-form__actions signin-form__actions">
                        <ButtonSuccess
                                :props="{disabled: loading}"
                                type="submit"
                                class="auth-form__submit">
                            {{$t('message.submit')}}
                        </ButtonSuccess>
                        <router-link to="sign-in" class="link">
                            {{$t('message.signIn')}}
                        </router-link>
                    </div>
                </v-form>
            </div>
        </CustomCard>
    </div>
</template>

<script>
import ButtonSuccess from '@components/Shared/Buttons/ButtonSuccess.vue';
import CustomCard from '@components/Shared/CustomCard.vue';
import rulesMixin from '@/mixins/Form/rules';

export default {
  name: 'ForgotPassword',
  components: {
    ButtonSuccess,
    CustomCard,
  },
  mixins: [rulesMixin],
  data() {
    return {
      email: '',
      loading: false,
    };
  },
  methods: {
    submit() {
      const { email } = this;
      if (this.formValidate(this.$refs.forgotPasswordForm)) {
        this.loading = true;
        this.$store.dispatch('password/forgotPassword', { email }).then(() => {
          this._showSuccessNotify(this.$t('message.emailWasSentSuccessful'));
          this.$router.push('/sign-in');
        }).catch((e) => {
          const { errors, message } = e.response.data;
          if (errors) {
            const errorsKeys = Object.keys(errors);
            errorsKeys.forEach((key) => {
              this._showErrorNotify(errors[key][0]);
            });
          } else {
            this._showErrorNotify(message);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
